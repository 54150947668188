<template lang="pug">
div
  b-row(v-show="isSubmitting")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )
  Form(
    v-show="!isSubmitting",
    :data="caseData",
    @submit="create",
    @isSubmitting="isSubmitting = true"
  )
</template>

<script>
import Form from '../shared/Form.vue'
import useCaseAdd from './useCaseAdd'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Form,
    Loading,
  },
  setup() {
    const { create, caseData, isSubmitting } = useCaseAdd()

    return {
      create,
      caseData,
      isSubmitting,
    }
  },
}
</script>
